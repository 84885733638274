import React from "react";

const Hero = () => {
    return <div class="hero">
        <div class="px-4 py-5 my-5 text-center">
            <h1 class="display-5 fw-bold text-body-emphasis">I am Emilie</h1>
            <div class="col-lg-6 mx-auto">
                <p class="lead mb-4 self-intro">A computer sicence college student, a creative web developer, an energetic life-long learner</p>
                {/* <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <button type="button" class="btn btn-dark btn-lg px-4 gap-3">Hire me</button>
                    <button type="button" class="btn btn-outline-secondary btn-lg px-4">Connect with me</button>
                </div> */}
            </div>
        </div>
    </div>
}

export default Hero;