import React from "react";
import SkillCard from "./SkillCard";
import { FaCode, FaHtml5, FaTools } from "react-icons/fa"

const Skills = () => {
    return <div >
        <h2 class="pb-2 text-center">My Skills</h2>
        <div className="skills">
            <SkillCard
                icon={<FaHtml5 size={42} />}
                skills={["HTML", "CSS", "JavaScript", "React", "Bootstrap", "JSON", "DOM", "jQuery"]}
                title="FrontEnd" />
            <SkillCard
                icon={<FaCode size={42} />}
                skills={["NodeJS", "Express", "Python", "Java", "SQL", "MongoDB", "Mongoose", "RESTAPI"]}
                title="BackEnd" />
            <SkillCard
                icon={<FaTools size={42} />}
                skills={["Git", "GitHub", "Postman", "Eclipse", "IntelliJ", "VSCode", "Render", "Cyclic"]}
                title="Tools" />
        </div>
    </div>

}

export default Skills;