import React from "react";

const Contact = () => {
    return <div class="contact-me">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>Contact Me</h4>
                    <h5>Address</h5>
                    <p>College of San Mateo<br />
                        1700 W. Hillsdale Boulevard<br />
                        San Mateo<br />
                        CA 94402 <br /></p>
                </div>
            </div>
        </div>
    </div>
}

export default Contact;