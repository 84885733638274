import React from "react";

const CertificateCard = ({ img, link }) => {
    return (
        <div className="col d-flex align-items-start">
            <a href={link} target="_blank" rel="noreferrer">
                <img
                    src={img}
                    alt="online courses certificate"
                    className="certificate-img"
                />
            </a>
        </div >)
}

export default CertificateCard;