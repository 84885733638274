import React from "react";

const Hobbies = () => {
    return <>
        <div class="topdiv">
            <h1>hello world</h1>
            <p class="subtitle">If you want to <em>achieve a success</em>, just try it. <b>Practice makes perfect. <em>You will never be ready</em></b>. </p>
        </div>
        <div class="cleardiv">
            <h2>What Do You Favorite?</h2>
            <p><em>We glad to know things which you <b>like most</b>.</em></p>
        </div>
        <div class="boxdiv">
            <h3>Music</h3>
            <p>Here are Emilie's top five favorite songs</p>
            <ol>
                <li><a href="https://www.bilibili.com/video/BV1sf4y1N7RH?t=1.2 target=' _blank'">Believer</a></li>
                <li><a href="https://www.bilibili.com/video/BV1Et411f7fu?from=search&seid=2422943490556133186&spm_id_from=333.337.0.0 target='_blank'">I Did Something Bad</a></li>
                <li><a href="https://www.bilibili.com/video/BV1Sp411f7gQ?from=search&seid=5691352007652267100&spm_id_from=333.337.0.0 target='_blank'">Carry me</a></li>
                <li><a href="https://www.bilibili.com/video/BV1ds411x7Eu?from=search&seid=8734519870291876049&spm_id_from=333.337.0.0 target='_blank'">Extreme Ways</a></li>
                <li><a href="https://www.bilibili.com/video/BV164411D7vc?from=search&seid=4220684909576200317&spm_id_from=333.337.0.0 target='_blank'">Lemon</a></li >
            </ol >
        </div >
        <div class="boxdiv">
            <h3>Favorite Food</h3>
            <p>Emiliw loves to enjoy the following food whenever she gets the opportunity! </p>
            <ul>
                <li>Peking Duck, China (<a href="https://en.wikipedia.org/wiki/Peking_duck">Wikipedia</a>)</li>
                <li>Sushi, Japan (<a href="https://en.wikipedia.org/wiki/Sushi">Wikipedia</a>)</li>
                <li>Ice creem, Global (<a href="https://en.wikipedia.org/wiki/Ice_cream">Wikipedia</a>)</li>
                <li>Chocolate, Mexico (<a href="https://en.wikipedia.org/wiki/Chocolate">Wikipedia</a>)</li>
                <li>Hamurger, United States (<a href="https://en.wikipedia.org/wiki/Hamburger">Wikipedia</a>)</li>
            </ul>
        </div>
        <div class="tablediv">
            <h3>Places I've Visited</h3>
            <table>
                <tr>
                    <td><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6yUPT6D7IQvZThjf2ON2GyLERRBZPZZ5Ifg&usqp=CAU" />
                        <p>Dali, Yunnan, China</p>
                    </td>
                </tr>
                <tr>
                    <td><img src="https://dimg06.c-ctrip.com/images/10070y000000mlx0z9B20_C_500_280_Q80.jpg" />
                        <p>Fuzi Miao, Nanjing, China</p>
                    </td>
                </tr>
                <tr>
                    <td><img src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcTN7p86un4F9NTeO7y18Hs3VEI-IjRcMoE489tyf-2TOurPKLWtK3OsoD-8J_iloHetnLL7u8YvhxT7S3eFwyaTCA" />
                        <p>Gugong Palace, Beijing, China</p>
                    </td>
                </tr>
                <tr>
                    <td><img src="https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcSe2gdBqSr0o3hMQ2vWiqwdPX4R7xk9B74njjrCFDH6GMib-FRLTO9Ta1SX6TeoQysyDtx_YUCW6KPJXTrmERcBhw" />
                        <p>Waitai, Shanghai, China</p>
                    </td>
                </tr>
                <tr>
                    <td><img src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcQxTEWJxU3LqhporKQKS7ShAx8PqjB9eEs4dNzO6JE8aZhthZ6_LMrd8Kj0wt3GGBdj_2ZUvbO66y6jESKhikSNuQ" />
                        <p>Classical Gardens of Suzhou, Suzhou, China</p>
                    </td>
                </tr>
            </table>
        </div>
        <div class="cleardiv">
            <p>I hope you've enjoyed my page!</p>
        </div>
    </>
}

export default Hobbies;