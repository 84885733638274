import React from "react";
import CertificateCard from "./CertificateCard";

const Certificates = () => {
    return (
        <div className="mt-5 container">
            <h2 className="text-center mb-4">Certificates</h2>
            <div className="row certificates">
                <CertificateCard
                    img="https://udemy-certificate.s3.amazonaws.com/image/UC-80469d58-090c-46ac-84a6-8e9846a1d4a8.jpg"
                    link="https://www.udemy.com/certificate/UC-80469d58-090c-46ac-84a6-8e9846a1d4a8/" />
                <CertificateCard
                    img={process.env.PUBLIC_URL + "/img2.png"}
                    link="https://coursera.org/share/0c3c599a71645d0a6ab1afd26771c4e5" />
                <CertificateCard
                    img={process.env.PUBLIC_URL + "/img3.jpg"}
                    link="https://credentials.edx.org/credentials/5d131fb149b24a49a13fb04bffa34e29"
                />
            </div>
        </div>
    )
}

export default Certificates;