import React from "react";
import ProjectCard from "./ProjectCard";

const Projects = () => {
    return (
        <div class="container px-4 py-5">
            <h2 class="pb-2 border-bottom text-center">My Projects</h2>
            <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <ProjectCard
                    title="Time Management"
                    subTitle=""
                    img=""
                    delpoy=""
                    link="https://github.com/emiliewz/Time-Management"
                    details="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sente"
                />
                <ProjectCard
                    title="Conversion Helper"
                    subTitle=""
                    img=""
                    delpoy=""
                    link="https://github.com/emiliewz/Conversion-Helper"
                    details="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sente"
                />
                <ProjectCard
                    title="Blog"
                    subTitle=""
                    img=""
                    delpoy=""
                    link="https://github.com/emiliewz/React-Blog"
                    details="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sente"
                />
            </div>
            {/* <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <ProjectCard
                    title="Keeper notes"
                    subTitle=""
                    img=""
                    delpoy=""
                    link="https://github.com/emiliewz/React-Blog"
                    details="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sente"
                />
                <ProjectCard
                    title="Tech Notes"
                    subTitle=""
                    img=""
                    delpoy=""
                    link="https://github.com/emiliewz/React-Blog"
                    details="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sente"
                />
                <ProjectCard
                    title="E-Commerce"
                    subTitle=""
                    img=""
                    delpoy=""
                    link="https://github.com/emiliewz/React-Blog"
                    details="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sente"
                />
            </div> */}
        </div>
    )
}

export default Projects;