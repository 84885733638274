import React from "react";

const SkillCard = ({ icon, skills, title }) => {
  return (
    <div className="skill-card">
      <div>{icon}</div>
      <h5 class="pb-2 border-bottom text-center">{title}</h5>
      {skills.map(s => <p>{s}</p>)}
    </div>
  )
}

export default SkillCard;