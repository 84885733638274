import React from "react";

const ProjectCard = ({ img, title, details, link, deploy }) => {
  return (
    <div class="col d-flex align-items-start">
      <div>
        {/* <img src={img} alt="project img" /> */}
        <h5><strong>{title}</strong></h5>
        <p>{details}</p>
        <div class="d-grid gap-2 d-sm-flex justify-content-sm-left">
          <a href={link} target="_blank" rel="noreferrer"><button type="button" class="btn btn-dark btn-md px-4 gap-3">Sorce Code</button></a>
          {/* <a href={link} target="_blank" rel="noreferrer"><button type="button" class="btn btn-dark btn-md px-4 gap-3">Live Deploy</button></a> */}
          {/* <a href={link} target="_blank" rel="noreferrer"><button type="button" class="btn btn-outline-secondary btn-md px-4">Sorce Code</button></a> */}
        </div>
      </div>
    </div >
  )
}

export default ProjectCard;