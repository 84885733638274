import React from "react";

const About = () => {
    return <div class="about-me mb-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>About Me</h4>
                    {/* <a href="./public/hobbies.html">My Hobbies</a> */}
                </div>
            </div>
        </div>
    </div>
}

export default About;